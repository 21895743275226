import { useState, useEffect } from "react";
import {
  LdsButton,
  LdsSwitch,
  LdsModal,
  LdsIcon,
  LdsToast,
  useLdsModal,
  useToastContext,
  LdsTextField,
  LdsLink
} from "@elilillyco/ux-lds-react";
import CreatableSelect from "react-select/creatable";
import "../../assets/components/OMMS/addtactic.scss";
import HeaderNav from "../HeaderNav";
import { useDispatch, useSelector } from "react-redux";
import { fetchMetaData } from "../../store/Components/OMMS/addtactic";
import { fetchOptions } from "../../store/Components/OMMS/getoptions";
import { addTactic } from "../../store/Components/OMMS/Vendorentries";
import { TacticbyID } from "../../store/Components/OMMS/getTacticbyID";
import { LdsLoadingSpinner } from "@elilillyco/ux-lds-react";
import { useNavigate } from "react-router-dom";
import { updateRequestForApproval } from "../../store/Components/OMMS/updateTactic";
import { fetchCommonTactic } from "../../store/Components/OMMS/commonTactic";


export default function TacticEntry() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(
    ({ commonTacticSlice }) => commonTacticSlice.commonData
  );
  const [deliverysourceinput, setdeliverysourceinput] = useState("");
  const [suppliernameinput, setsuppliernameinput] = useState("");
  const [platforminput, setplatforminput] = useState("");
  const [contacttypeinput, setcontacttypeinput] = useState("");
  const [deliverysourcetypeinput, setdeliverysourcetypeinput] = useState("");
  const [deliverychannelinput, setdeliverychannelinput] = useState("");
  const [engagementbasedtacticinput, setengagementbasedtacticinput] = useState("");
  const [activitytypeinput, setactivitytypeinput] = useState("");
  const [activitydatatypeinput, setactivitydatatypeinput] = useState("");
  const [activitytypeclassificationinput, setactivitytypeclassificationinput] =
    useState("");
  const [activitytypedefinitioninput, setactivitytypedefinitioninput] =
    useState("");
    const [engagementdepthinput, setengagementdepthinput] =
    useState("");
    const [conbasisclassinput, setconbasisclassinput] =
    useState("");
    const [perbasisclassinput, setperbasisclassinput] =
    useState("");
    const [engrtagainstinput, setengrtagainstinput] =
    useState("");
  const [isCap, setisCap] = useState(data.isCap);
  const [isDigital, setisDigital] = useState();
  const [isPld, setisPld] = useState();
  const [isRm, setisRm] = useState();
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const { isModalOpen: isDraftModalOpen, setIsModalOpen: setisDraftModalOpen } =
    useLdsModal();
  const MetaData = useSelector((state) => state.metaDataSlice.MetaData);
  const OverviewOpts = useSelector((state) => state?.OptionsSlice.Options);
  const loader = useSelector(({ OptionsSlice }) => OptionsSlice.loader);

  const isedit_loader = useSelector((state) => state.getTacticbyIDSlice.loader);


  function checkCap() {
    if (isRm === true && isPld === true) {
      setisCap(true);
    } else {
      setisCap(false);
    }
  }
  const role = useSelector(({ user }) => user.role_ids);
  const defaultOptions = [
    {
      label: "Overview Option",
      options: [],
    },
    {
      label: "Metadata Options",
      options: [],
    },
  ];
  const [deliverySourceOption, setDeliverySourceOpt] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [supplierNameOption, setSupplierNameOpt] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [platformOption, setPlatformOpt] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [contactTypeOption, setContactTypeOpt] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [deliverySourceTypeOption, setDeliverySourceTypeOpt] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [deliveryChannelOption, setDeliveryChannelOpt] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [engagementBasedTacticOption, setEngagementBasedTacticOpt] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [actTypOption, setActTypOpt] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [actDataTypOption, setActDataTypOpt] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [actTypClassificationOption, setActTypClassificationOpt] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [actTypDefinitionOption, setActTypDefinitionOpt] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [engDepOptions, setengDepOptions] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [conBasClassOptions, setconBasClassOptions] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [perBasClassOptions, setperBasClassOptions] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [engRtOptions, setengRtOptions] = useState(
    JSON.parse(JSON.stringify(defaultOptions))
  );
  const [metaFirstRender, setMetaFirstRender] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [isAddMetric, setIsAddMetric] = useState(false);
  const { addToast } = useToastContext();

  let vc_del_src = document
    .getElementById("delivery_source")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_sup_name = document
    .getElementById("supplier_name")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_platform = document
    .getElementById("platform")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_contact_type = document
    .getElementById("contact_type")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_del_src_type = document
    .getElementById("delivery_source_type")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_del_channel = document
    .getElementById("delivery_channel")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_engage_based_tactic = document
    .getElementById("engagement_based_tactic")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_act_data_typ = document
    .getElementById("act_data_typ")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_act_typ = document
    .getElementById("act_typ")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_act_data_typ_class = document
    .getElementById("act_data_typ_class")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_act_data_typ_def = document
    .getElementById("act_data_typ_def")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_act_typ_add = document
    .getElementById("act_typ_add")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_act_data_typ_class_add = document
    .getElementById("act_data_typ_class_add")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_act_data_typ_add = document
    .getElementById("act_data_typ_add")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
  let vc_act_data_typ_def_add = document
    .getElementById("act_data_typ_def_add")
    ?.querySelector(" .css-910r8z-singleValue")
    ?.textContent?.trim();
    let vc_eng_dep_add = document.getElementById('eng_dep')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_con_basis_add = document.getElementById('cont_basis')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_per_basis_add = document.getElementById('per_basis')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_eng_rate_add = document.getElementById('eng_rate')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();  
  const commonData = useSelector(
    ({ commonTacticSlice }) => commonTacticSlice.commonData
  );
  
  const commonLoader = useSelector(
    ({ commonTacticSlice }) => commonTacticSlice.commonLoader
  );
  const requestSliceloader = useSelector(({ requestSlice }) => requestSlice.loader);
  const [stAttributes, setStAttributes] = useState([
    {
      id: 1,
      engagementClassification: "",
      metricLabel: "",
      hierarchyClassification: "",
      metricDefinition: "",
      engagementDepth:0,
      contractBasisClassification:"",
      performanceBasisClassification:"",
      engagementRateCalculatedAgainst:"",
    },
  ]);
  const [newAttributes, setNewAttributes] = useState([
    {
      id: 1,
      engagementClassification: "",
      metricLabel: "",
      hierarchyClassification: "",
      metricDefinition: "",
      engagementDepth:0,
      contractBasisClassification:"",
      performanceBasisClassification:"",
      engagementRateCalculatedAgainst:"",
    },
  ]);
  const [nAttributes, setNAttributes] = useState([
    {
      id: 1,
      engagementClassification: "",
      metricLabel: "",
      hierarchyClassification: "",
      metricDefinition: "",
      engagementDepth:0,
      contractBasisClassification:"",
      performanceBasisClassification:"",
      engagementRateCalculatedAgainst:"",
      updatedFields:[],
    },
  ]);
  const [dupAttributes, setDupAttributes] = useState([
    {
      id: 1,
      engagementClassification: "",
      metricLabel: "",
      hierarchyClassification: "",
      metricDefinition: "",
      engagementDepth:0,
      contractBasisClassification:"",
      performanceBasisClassification:"",
      engagementRateCalculatedAgainst:"",
    },
  ]);

  useEffect(() => {
    setNAttributes((prevAttributes) => [
      ...commonData.map((values) => ({
        id: values.omms_overview_detail_id,
        engagementClassification: values.engagementClassification,
        metricLabel: values.metricLabel,
        hierarchyClassification: values.hierarchyClassification,
        metricDefinition: values.metricDefinition,
        engagementDepth: values.engagementDepth,
        contractBasisClassification: values.contractBasisClassification,
        performanceBasisClassification: values.performanceBasisClassification,
        engagementRateCalculatedAgainst: values.engagementRateCalculatedAgainst,
      })),
    ]);
    setDupAttributes((prevAttributes) => [
      ...commonData.map((values) => ({
        id: values.omms_overview_detail_id,
        engagementClassification: values.engagementClassification,
        metricLabel: values.metricLabel,
        hierarchyClassification: values.hierarchyClassification,
        metricDefinition: values.metricDefinition,
        engagementDepth: values.engagementDepth,
        contractBasisClassification: values.contractBasisClassification,
        performanceBasisClassification: values.performanceBasisClassification,
        engagementRateCalculatedAgainst: values.engagementRateCalculatedAgainst,
      })),
    ]);
  }, [commonData]);
  const [numberSC, SetNumberSC] = useState(1);
  const handleEditInputChange = (id, field, value) => {
    const updateAtt = nAttributes.map((div) =>
      div.id === id
        ? {
            ...div,
            [field]: value,
          }
        : div
    );
    setNAttributes(updateAtt);
  };
  const handleInputChange = (id, field, value) => {
    const updateAtt = newAttributes.map((div) =>
      div.id === id
        ? {
            ...div,
            [field]: value,
          }
        : div
    );
    setNewAttributes(updateAtt);
  };
  const handleAddAtt = () => {
    const newAtt = {
      id: newAttributes[newAttributes.length - 1].id + 1,
      engagementClassification: "",
      metricLabel: "",
      hierarchyClassification: "",
      metricDefinition: "",
      engagementDepth:0,
      contractBasisClassification:"",
      performanceBasisClassification:"",
      engagementRateCalculatedAgainst:"",
    };
    const nAtt = {
      id: stAttributes[stAttributes.length - 1].id + 1,
      engagementClassification: "",
      metricLabel: "",
      hierarchyClassification: "",
      metricDefinition: "",
      engagementDepth:0,
      contractBasisClassification:"",
      performanceBasisClassification:"",
      engagementRateCalculatedAgainst:"",
    };
    setNewAttributes([...newAttributes, newAtt]);
    setStAttributes([...stAttributes, nAtt]);
  };
  const handleDeleteAtt = (id) => {
    const updatedAtt = newAttributes.filter((div) => div.id !== id);
    const upAtt = stAttributes.filter((div) => div.id !== id);
    setNewAttributes(updatedAtt);
    setStAttributes(upAtt);
  };

  const URLParams = new URLSearchParams(window.location.search);
  const delivery_source = URLParams.get("delivery_source")==='null'?'':URLParams.get("delivery_source");
  const supplier_name = URLParams.get("supplier_name")==='null'?'':URLParams.get("supplier_name");
  const platform = URLParams.get("platform")==='null'?'':URLParams.get("platform");
  const contact_type = URLParams.get("contact_type")==='null'?'':URLParams.get("contact_type");
  const delivery_source_type = URLParams.get("delivery_source_type")==='null'?'':URLParams.get("delivery_source_type");
  const delivery_channel = URLParams.get("delivery_channel")==='null'?'':URLParams.get("delivery_channel");
  const engagement_based_tactic = URLParams.get("engagement_based_tactic")==='null'?'':URLParams.get("engagement_based_tactic");

  const [d_source, setd_source] =useState(delivery_source)
  const [s_name, sets_name] =useState(supplier_name)
  const [pl_form, setpl_form] =useState(platform)
  const [c_type, setc_type] =useState(contact_type)
  const [d_s_type, setd_s_type] =useState(delivery_source_type)
  const [d_channel, setd_channel] =useState(delivery_channel)
  const [engage_tactic, setengage_tactic] =useState(engagement_based_tactic)

  const setOptions = () => {
    let vc_del_src = document.getElementById('delivery_source')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_sup_name = document.getElementById('supplier_name')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_platform = document.getElementById('platform')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_contact_type = document.getElementById('contact_type')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_del_src_type = document.getElementById('delivery_source_type')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_del_channel = document.getElementById('delivery_channel')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_engage_based_tactic = document.getElementById('engagement_based_tactic')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_act_data_typ = document.getElementById('act_data_typ')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_act_typ = document.getElementById('act_typ')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_act_data_typ_class = document.getElementById('act_data_typ_class')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_act_data_typ_def = document.getElementById('act_data_typ_def')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_act_typ_add = document.getElementById('act_typ_add')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_act_data_typ_class_add = document.getElementById('act_data_typ_class_add')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_act_data_typ_add = document.getElementById('act_data_typ_add')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_act_data_typ_def_add = document.getElementById('act_data_typ_def_add')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_eng_dep_add = document.getElementById('eng_dep')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_con_basis_add = document.getElementById('cont_basis')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_per_basis_add = document.getElementById('per_basis')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();
    let vc_eng_rate_add = document.getElementById('eng_rate')?.querySelector(' .css-910r8z-singleValue')?.textContent?.trim();  

    return { vc_con_basis_add,vc_per_basis_add,vc_eng_dep_add,vc_eng_rate_add, vc_del_src, vc_sup_name, vc_platform, vc_contact_type, vc_del_src_type, vc_del_channel, vc_engage_based_tactic, vc_act_data_typ, vc_act_typ, vc_act_data_typ_class, vc_act_data_typ_def, vc_act_typ_add, vc_act_data_typ_class_add, vc_act_data_typ_add, vc_act_data_typ_def_add}
  }

  const[del_src_code, setDel_src_code]=useState([])
  let queryParams = "";
  useEffect(() => {
    dispatch(fetchMetaData()).then((data)=>setDel_src_code(data?.payload?.data.filter((obj)=>obj.attributeType==='delivery_source')));
  }, []);

  useEffect(() => {
    if (isEdit === false) {
      queryParams = "";

      if (deliverysourceinput !== "") {
        queryParams += `deliverySource=${deliverysourceinput?.value}`;
      }
      if (suppliernameinput !== "" && suppliernameinput !== undefined) {
        queryParams += `&supplierName=${encodeURIComponent(
          suppliernameinput?.value
        )}`;
      }
      if (platforminput !== "" && platforminput !== undefined) {
        queryParams += `&platform=${platforminput?.value}`;
      }
      if (contacttypeinput !== "" && contacttypeinput !== undefined) {
        queryParams += `&contactType=${contacttypeinput?.value}`;
      }
      if (
        deliverysourcetypeinput !== "" &&
        deliverysourcetypeinput !== undefined
      ) {
        queryParams += `&deliverySourceType=${deliverysourcetypeinput?.value}`;
      }
      if (deliverychannelinput !== "" && deliverychannelinput !== undefined) {
        queryParams += `&deliveryChannel=${deliverychannelinput?.value}`;
      }
      if (engagementbasedtacticinput !== "" && engagementbasedtacticinput !== undefined) {
        queryParams += `&engagementBasedTactic=${engagementbasedtacticinput?.value}`;
      }
      if (queryParams === "") {
      } else {
        dispatch(fetchOptions(queryParams));
        setContactTypeOpt(OverviewOpts?.contactType);
        setSupplierNameOpt(OverviewOpts?.supplierName);
        setPlatformOpt(OverviewOpts?.platform);
        setDeliverySourceTypeOpt(OverviewOpts.deliverySourceType);
        setDeliveryChannelOpt(OverviewOpts.deliveryChannel);
        setEngagementBasedTacticOpt(OverviewOpts.engagementBasedTactic);
      }
    }
  }, [
    deliverysourceinput,
    suppliernameinput,
    platforminput,
    contacttypeinput,
    deliverysourcetypeinput,
    deliverychannelinput,
    engagementbasedtacticinput
  ]);

  useEffect(() => {
    const URLParams = new URLSearchParams(window.location.search);
    const delivery_source = URLParams.get("delivery_source")==='null'?'':URLParams.get("delivery_source");
    const supplier_name = URLParams.get("supplier_name")==='null'?'':URLParams.get("supplier_name");
    const platform = URLParams.get("platform")==='null'?'':URLParams.get("platform");
    const contact_type = URLParams.get("contact_type")==='null'?'':URLParams.get("contact_type");
    const delivery_source_type = URLParams.get("delivery_source_type")==='null'?'':URLParams.get("delivery_source_type");
    const delivery_channel = URLParams.get("delivery_channel")==='null'?'':URLParams.get("delivery_channel");
    const is_cap = URLParams.get("is_cap")==='yes'?true:false
    const is_rm = URLParams.get("is_rm")==='yes'?true:false;
    const is_digital = URLParams.get("is_digital")==='yes'?true:false;
    const is_pld = URLParams.get("is_pld")==='yes'?true:false;
    const approval_status= URLParams.get("approval_status");
    const type = URLParams.get("type")
    if (
      delivery_source ||
      supplier_name ||
      platform ||
      contact_type ||
      delivery_channel ||
      delivery_source_type ||
      approval_status
    ) 
    {
    if(type==='edit'){
      setisEdit(true);
      setIsAddMetric(false);
      dispatch(
        fetchCommonTactic({
          delivery_source: delivery_source,
          supplier_name: supplier_name,
          delivery_source_type: delivery_source_type,
          delivery_channel: delivery_channel,
          engagement_based_tactic: engagement_based_tactic,
          platform: platform,
          contact_type: contact_type,
          iscap:is_cap,
          approval_status: approval_status
        })
      );
      setisPld(is_pld);
      setisRm(is_rm);
      setisDigital(is_digital);
    }
    if(type==='add'){
      setisEdit(false);
      setIsAddMetric(true);
      setisPld(is_pld);
      setisRm(is_rm);
      setisDigital(is_digital);
    }
    }
    return;
  }, [dispatch]);
  useEffect(() => {
    if (MetaData && MetaData.length > 0 && !metaFirstRender) {
      MetaData.forEach((item) => {
        const { attributeType, attributeValue } = item;
        if (attributeType === "delivery_source") {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          deliverySourceOption[1].options.push(option);
          deliverySourceOption[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );
        } else if (attributeType.toLowerCase() === "supplier_name") {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          supplierNameOption[1].options.push(option);
          supplierNameOption[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );
        } else if (attributeType.toLowerCase() === "delivery_source_type") {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          deliverySourceTypeOption[1].options.push(option);
          deliverySourceTypeOption[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );
        } else if (attributeType.toLowerCase() === "delivery_channel") {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          deliveryChannelOption[1].options.push(option);
          deliveryChannelOption[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );
        } else if (attributeType.toLowerCase() === "engagement_based_tactic") {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          engagementBasedTacticOption[1].options.push(option);
          engagementBasedTacticOption[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );
        } else if (attributeType.toLowerCase() === "platform") {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          platformOption[1].options.push(option);
          platformOption[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );
        } else if (attributeType.toLowerCase() === "contact_type") {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          contactTypeOption[1].options.push(option);
          contactTypeOption[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );
        } else if (
          attributeType.toLowerCase() === "engagement_classification"
        ) {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          actTypOption[1].options.push(option);
          actTypOption[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );
        } else if (attributeType.toLowerCase() === "metric_label") {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          actDataTypOption[1].options.push(option);
          actDataTypOption[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );
        } else if (attributeType.toLowerCase() === "hierarchy_classification") {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          actTypClassificationOption[1].options.push(option);
          actTypClassificationOption[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );
          
        }else if (attributeType.toLowerCase() === "engagement_depth") {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          engDepOptions[1].options.push(option);
          engDepOptions[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );} 
          else if (attributeType.toLowerCase() === "contract_basis_classification") {
            const option = {
              label: attributeValue?.trim(),
              value: attributeValue?.trim(),
            };
            conBasClassOptions[1].options.push(option);
            conBasClassOptions[1].options.sort((a, b) =>
              a.label?.localeCompare(b.label)
            );} 
            else if (attributeType.toLowerCase() === "performance_basis_classification") {
              const option = {
                label: attributeValue?.trim(),
                value: attributeValue?.trim(),
              };
              perBasClassOptions[1].options.push(option);
              perBasClassOptions[1].options.sort((a, b) =>
                a.label?.localeCompare(b.label)
              );} 
              else if (attributeType.toLowerCase() === "engagement_rate_calculated_against") {
                const option = {
                  label: attributeValue?.trim(),
                  value: attributeValue?.trim(),
                };
                engRtOptions[1].options.push(option);
                engRtOptions[1].options.sort((a, b) =>
                  a.label?.localeCompare(b.label)
                );} 
        else if (attributeType.toLowerCase() === "metric_definition") {
          const option = {
            label: attributeValue?.trim(),
            value: attributeValue?.trim(),
          };
          actTypDefinitionOption[1].options.push(option);
          actTypDefinitionOption[1].options.sort((a, b) =>
            a.label?.localeCompare(b.label)
          );
        }
      });
      setMetaFirstRender(true);
    }
  }, [MetaData]);
  function retunToOverview() {
    window.location.href = "/omms";
  }
  const isAnyValuePresent = (arr) => {
    return arr.some((obj) => {
      // Get all keys except 'id'
      const keysToCheck = Object.keys(obj).filter((key) => key !== "id");
      // Check if any of the values for the keysToCheck are truthy
      return keysToCheck.some((key) => !!obj[key]);
    });
  };
  function getCurrentUserId() {
    const currentRole = sessionStorage.getItem("role");
    const currentUserData = role.filter(
      (roleData) => Object.keys(roleData)[0] === currentRole
    );
    return currentUserData[0][currentRole];
  }
  function handleClickDraftOpen() {
    const { vc_del_src, vc_sup_name, vc_platform, vc_contact_type, vc_del_src_type, vc_del_channel, vc_engage_based_tactic, vc_act_data_typ, vc_act_typ, vc_act_data_typ_class, vc_act_data_typ_def, vc_act_typ_add, vc_act_data_typ_class_add, vc_act_data_typ_add, vc_act_data_typ_def_add} = setOptions();
    if (isEdit) {
      if (
        vc_del_src === "Select or Type Delivery Source" &&
        vc_sup_name === "Select or Type Supplier Name" &&
        vc_platform === "Select or Type Platform" &&
        vc_contact_type === "Select or Type Contact Type" &&
        vc_del_src_type === "Select or Type Delivery Source Type" &&
        vc_del_channel === "Select or Type Delivery Channel" &&
        vc_engage_based_tactic === "Select or Type Engagement Based Tactic" &&
        vc_act_data_typ === "Select or Type Metric Label" &&
        vc_act_typ === "Select or Type Engagement Classification" &&
        vc_act_data_typ_def === "Select or Type Metric Definition" &&
        vc_act_data_typ_class === "Select or Type Hierarchy Classification"
      ) {
        addToast({
          toastMessage: "Please enter atleast 1 value",
          actionText: "",
          variant: "warning",
          position: "top",
          align: "center",
          light: false,
          timeout: 5000,
          dismissible: true,
          inline: false,
          autoDismiss: true,
        });
        return;
      }
    } else {
      if (
        vc_del_src === "Select or Type Delivery Source" &&
        vc_sup_name === "Select or Type Supplier Name" &&
        vc_platform === "Select or Type Platform" &&
        vc_contact_type === "Select or Type Contact Type" &&
        vc_del_src_type === "Select or Type Delivery Source Type" &&
        vc_del_channel === "Select or Type Delivery Channel" &&
        (isEdit
          ? isAnyValuePresent(newAttributes)
          : !isAnyValuePresent(newAttributes))
      ) {
        addToast({
          toastMessage: "Please enter atleast 1 value",
          actionText: "",
          variant: "warning",
          position: "top",
          align: "center",
          light: false,
          timeout: 5000,
          dismissible: true,
          inline: false,
          autoDismiss: true,
        });
        return;
      }
    }
    if (isEdit) {
      const updatedFields = [];
      const tacticObj = {

        deliverySource:
          vc_del_src === "Select or Type Delivery Source" ? "" : vc_del_src,
        supplierName:
          vc_sup_name === "Select or Type Supplier Name" ? "" : vc_sup_name,
        deliverySourceType:
          vc_del_src_type === "Select or Type Delivery Source Type"
            ? ""
            : vc_del_src_type,
        deliveryChannel:
          vc_del_channel === "Select or Type Delivery Channel"
            ? ""
            : vc_del_channel,
        engagementBasedTactic:
            vc_engage_based_tactic === "Select or Type Engagement Based Tactic"
              ? ""
              : vc_engage_based_tactic,
        platform: vc_platform === "Select or Type Platform" ? "" : vc_platform,
        contactType:
          vc_contact_type === "Select or Type Contact Type"
            ? ""
            : vc_contact_type,
        engagementClassification:
          vc_act_typ === "Select or Type Engagement Classification"
            ? ""
            : vc_act_typ,
        metricLabel:
          vc_act_data_typ === "Select or Type Metric Label"
            ? ""
            : vc_act_data_typ,
        hierarchyClassification:
          vc_act_data_typ_class === "Select or Type Hierarchy Classification"
            ? ""
            : vc_act_data_typ_class,
        metricDefinition:
          vc_act_data_typ_def === "Select or Type Metric Definition"
            ? ""
            : vc_act_data_typ_def,

        approvalStatus: "",
        isDigital: isDigital,
        isPld: isPld,
        isRm: isRm,
        isCap: isCap,
        isUpdated: false,
        isPersonalPromotional: false,
        updatedFields: [],
        updated_by: "",
        created_by: getCurrentUserId(),
      };
      Object.keys(tacticObj).forEach((field) => {
        if (tacticObj[field] !== data[field]) {
          updatedFields.push(field);
        }
      });
      const fieldsToCheck = [
        "deliverySource",
        "deliveryChannel",
        "supplierName",
        "platform",
        "contactType",
        "deliverySourceType",
        "engagementBasedTactic"
      ];
      const updatedFieldsToCheck = updatedFields.filter((field) =>
        fieldsToCheck.includes(field)
      );
     
    }

    setisDraftModalOpen(true);

  }
  function handleClickDraftClose() {
    setisDraftModalOpen(false);
  }
  function areValuesPresentInArray(arr) {
    for (const obj of arr) {
      for (const key in obj) {
        if (!obj[key] && obj[key] !== 0) {
          return false;
        }
      }
    }
    return true;
  }

  function handleClickSubmitOpen() {
   const ed = newAttributes.map(obj=>parseInt(obj.engagementDepth))
   const edn = nAttributes.map(obj=>obj.engagementDepth===null ||obj.engagementDepth===undefined? '' : parseInt(obj.engagementDepth))
   let regexx = /[^\w\s-]/g;
   function hasSpecialCharacters(input) {
    var regex = /[^\w\s-]/g;
    return regex.test(input);
    }
    const newA = newAttributes.map(obj => {
        if (hasSpecialCharacters(obj.engagementClassification) ||
            hasSpecialCharacters(obj.metricLabel) ||
            hasSpecialCharacters(obj.hierarchyClassification) ||
            hasSpecialCharacters(obj.contractBasisClassification) ||
            hasSpecialCharacters(obj.performanceBasisClassification) ||
            hasSpecialCharacters(obj.engagementRateCalculatedAgainst)) {
              return true
        }
        else{
          return false
        }
    });
    const nA = nAttributes.map(obj => {
      if (hasSpecialCharacters(obj.engagementClassification) ||
          hasSpecialCharacters(obj.metricLabel) ||
          hasSpecialCharacters(obj.hierarchyClassification) ||
          hasSpecialCharacters(obj.contractBasisClassification) ||
          hasSpecialCharacters(obj.performanceBasisClassification) ||
          hasSpecialCharacters(obj.engagementRateCalculatedAgainst)) {
            return true
      }
      else{
        return false
      }
  });
  const nAEmpty = nAttributes.some(obj => {
    if (obj.engagementClassification==('' || null || undefined) ||
        obj.metricLabel==('' || null || undefined) ||
        obj.hierarchyClassification==('' || null || undefined) ||
        obj.contractBasisClassification==('' || null || undefined) ||
        obj.performanceBasisClassification==('' || null || undefined) ||
        obj.engagementDepth==('' || null || undefined) ||
        obj.metricDefinition==('' || null || undefined) ||
        obj.engagementRateCalculatedAgainst==('' || null || undefined)) {
          return true
    }
    else{
      return false
    }
});

const newAEmpty = newAttributes.some(obj => {
  if (obj.engagementClassification===(''||null || undefined) ||
      obj.metricLabel===(''||null || undefined) ||
      obj.hierarchyClassification===(''||null || undefined) ||
      obj.contractBasisClassification===(''||null || undefined) ||
      obj.performanceBasisClassification===(''||null || undefined) ||
      obj.engagementDepth===(''||null || undefined) ||
      obj.metricDefinition===(''||null || undefined) ||
      obj.engagementRateCalculatedAgainst===(''||null || undefined)) {
        return true
  }
  else{
    return false
  }
});
const dr = commonData.map(obj=>{if(obj.approvalStatus==="DRAFT"){
  return true
}
else{
  return false
}
})

    if (isEdit) {
      let changeCount = 0;
      if (d_source !== delivery_source && delivery_source !== 'null' && delivery_source !== '') changeCount++;
      if (supplier_name !== s_name && supplier_name !== 'null' && supplier_name !== '') changeCount++;
      if (delivery_source_type !== d_s_type && delivery_source_type !== 'null' && delivery_source_type !== '') changeCount++;
      if (d_channel !== delivery_channel && delivery_channel !== 'null' && delivery_channel !== '') changeCount++;
      if (engage_tactic !== engagement_based_tactic && engagement_based_tactic !== 'null' && engagement_based_tactic !== '') changeCount++;
      if (pl_form !== platform && platform !== 'null' && platform !== '') changeCount++;
      if (c_type !== contact_type && contact_type !== 'null' && contact_type !== '') changeCount++;
      if (changeCount > 1 && dr[0]===false) 
        {
        // More than one field is updated, show a toast
        addToast({
          toastMessage: "More than one core fields cannot be edited at a time",
          actionText: "",
          variant: "warning",
          position: "top",
          align: "center",
          dismissible: true,
          light: false,
          timeout: 5000,
          dismissible: true,
          inline: false,
          autoDismiss: true,
        });
        
        return;
        
      }
    }
    if (
      vc_del_src === "Select or Type Delivery Source" ||
      vc_sup_name === "Select or Type Supplier Name" ||
      vc_platform === "Select or Type Platform" ||
      vc_contact_type === "Select or Type Contact Type" ||
      vc_del_src_type === "Select or Type Delivery Source Type" ||
      vc_del_channel === "Select or Type Delivery Channel" ||
      vc_act_data_typ === "Select or Type Metric Label" ||
      vc_act_typ === "Select or Type Engagement Classification" ||
      vc_act_data_typ_def === "Select or Type Metric Definition" ||
      vc_act_data_typ_class === "Select or Type Hierarchy Classification" ||
      vc_act_data_typ_add === "Select or Type Metric Label" ||
      vc_act_typ_add === "Select or Type Engagement Classification" ||
      vc_act_data_typ_def_add === "Select or Type Metric Definition" ||
      vc_act_data_typ_class_add === "Select or Type Hierarchy Classification" ||
      vc_eng_dep_add === "Select or Type Engagement Depth" ||
      vc_con_basis_add === "Select or Type Contract Basis Classification" ||
      vc_per_basis_add === "Select or Type Performance Basis Classification" ||
      vc_eng_rate_add === "Select or Type Engagement Rate Calculated Against" ||
      (isEdit
        ? areValuesPresentInArray(newAttributes)
        : !areValuesPresentInArray(newAttributes))
    ) {
      addToast({
        toastMessage: "Please enter all values",
        actionText: "",
        variant: "warning",
        position: "top",
        align: "center",
        light: false,
        timeout: 5000,
        dismissible: true,
        inline: false,
        autoDismiss: true,
      });
    } 
    else if (
      d_source==(''|| "null" || null || undefined) ||
      s_name==(''||  "null" ||null || undefined)||
      pl_form==(''|| "null" ||null || undefined)||
      c_type==(''|| "null" ||null || undefined)||
      d_s_type==(''|| "null" ||null || undefined)||
      d_channel==(''|| "null" ||null || undefined)||nAEmpty===true&&
      isEdit    
    ) {
      addToast({
        toastMessage: "Please enter all values",
        actionText: "",
        variant: "warning",
        position: "top",
        align: "center",
        light: false,
        timeout: 5000,
        dismissible: true,
        inline: false,
        autoDismiss: true,
      });
    } 
    else if(
      d_source===(''||null || undefined) ||
      s_name===(''||null || undefined)||
      pl_form===(''||null || undefined)||
      c_type===(''||null || undefined)||
      d_s_type===(''||null || undefined)||
      d_channel===(''||null || undefined)||newAEmpty===true&&
      !isEdit    
    ) {
      addToast({
        toastMessage: "Please enter all values",
        actionText: "",
        variant: "warning",
        position: "top",
        align: "center",
        light: false,
        timeout: 5000,
        dismissible: true,
        inline: false,
        autoDismiss: true,
      });
    } 
    // else if( ed.some(obj=> isNaN(obj)) && !isEdit){
    //   addToast({
    //     toastMessage: "Enagement Depth value should be a number",
    //     actionText: "",
    //     variant: "warning",
    //     position: "top",
    //     align: "center",
    //     light: false,
    //     timeout: 5000,
    //     dismissible: true,
    //     inline: false,
    //     autoDismiss: true,
    //   });
    // }
    // else if(edn.some(obj=> isNaN(obj)) && isEdit){
    //   addToast({
    //     toastMessage: "Enagement Depth value should be a number",
    //     actionText: "",
    //     variant: "warning",
    //     position: "top",
    //     align: "center",
    //     light: false,
    //     timeout: 5000,
    //     dismissible: true,
    //     inline: false,
    //     autoDismiss: true,
    //   });
    // }
    else {
      setIsModalOpen(true);
    }
  }
  function handleClickSubmitClose() {
    setIsModalOpen(false);
  }
  
  const [delSCinput, setdelSCinput]=useState()
  const [delSC,setDelSC]=useState()
  useEffect(()=>{
    setDelSC(del_src_code?.filter((obj)=>obj.attributeValue===d_source)[0]?.subAttributeValue)
  },[del_src_code,d_source])
  
  // const [delSCreq,setdelSCreq]=useState()
  console.log(delSC,'delSC')
  function handleClickSubmit(status) {

    const finalAttributes = newAttributes.map((obj) => {
      const { id, ...rest } = obj;
      return rest;
    });
    const tacticscObj = {
      deliverySource:
        vc_del_src === "Select or Type Delivery Source" ? "" : vc_del_src,
      deliverySourceCode: delSC? delSC:delSCinput,
      supplierName:
        vc_sup_name === "Select or Type Supplier Name" ? "" : vc_sup_name,
      deliverySourceType:
        vc_del_src_type === "Select or Type Delivery Source Type"
          ? ""
          : vc_del_src_type,
      deliveryChannel:
        vc_del_channel === "Select or Type Delivery Channel"
          ? ""
          : vc_del_channel,
      engagementBasedTactic:
        vc_engage_based_tactic === "Select or Type Engagement Based Tactic"
          ? ""
          : vc_engage_based_tactic,
      platform: vc_platform === "Select or Type Platform" ? "" : vc_platform,
      contactType:
        vc_contact_type === "Select or Type Contact Type"
          ? ""
          : vc_contact_type,

      approvalStatus: "",
      isDigital: isDigital ? isDigital : false,
      isPld: isPld ? isPld : false,
      isRm: isRm ? isRm : false,
      isCap: isCap? isCap : false,
      isUpdated: "Updated",
      isPersonalPromotional: false,
      updated_by: getCurrentUserId(),

      created_by: getCurrentUserId(),
    };

    const fAttributes = nAttributes.map((nAttr, index) => {
      const dupAttr = dupAttributes[index];
      const changedKeys = [];
      Object.keys(nAttr).forEach(key => {
        if (nAttr[key] !== dupAttr[key]) {
          changedKeys.push(key);
        }
      });
      return {
        ...nAttr,
        updatedFields: changedKeys
      };
    });
    
    // Initialize tacticObj
    let tacticObj = {};
    
    // Iterate over fAttributes
    fAttributes.forEach((obj) => {
      // Copy updatedFields array
      let updatedFields = [...obj.updatedFields];
    
      // Check if delivery_source has changed
      if (d_source !== delivery_source) {
        updatedFields.push('deliverySource');
        updatedFields.push('deliverySourceCode');
      }
      if (supplier_name !== s_name) {
        updatedFields.push('supplierName');
      }
      if (delivery_source_type !== d_s_type) {
        updatedFields.push('deliverySourceType');
      }
      if (d_channel !== delivery_channel) {
        updatedFields.push('deliveryChannel');
      }
      if (engage_tactic !== engagement_based_tactic) {
        updatedFields.push('engagementBasedTactic');
      }
      if (pl_form !== platform) {
        updatedFields.push('platform');
      }
      if (c_type!== contact_type) {
        updatedFields.push('contactType');
      }
     
    
      // Construct new object for tacticObj
      if (updatedFields.length > 0) {
        // Construct new object for tacticObj
        tacticObj[obj.id] = {
          ...tacticscObj,
          engagementClassification: obj.engagementClassification,
          metricLabel: obj.metricLabel,
          hierarchyClassification: obj.hierarchyClassification,
          metricDefinition: obj.metricDefinition,
          engagementDepth:obj.engagementDepth,
          contractBasisClassification: obj.contractBasisClassification,
          performanceBasisClassification: obj.performanceBasisClassification,
          engagementRateCalculatedAgainst: obj.engagementRateCalculatedAgainst,
          updatedFields: updatedFields
        };
      }
    });


  
    const tacticAddObj = {
      coreFields: {
        deliverySource:
        vc_del_src === "Select or Type Delivery Source" ? "" : vc_del_src,
        deliverySourceCode: delSC? delSC:delSCinput,
      supplierName:
        vc_sup_name === "Select or Type Supplier Name" ? "" : vc_sup_name,
      deliverySourceType:
        vc_del_src_type === "Select or Type Delivery Source Type"
          ? ""
          : vc_del_src_type,
      deliveryChannel:
        vc_del_channel === "Select or Type Delivery Channel"
          ? ""
          : vc_del_channel,
      engagementBasedTactic:
        vc_engage_based_tactic === "Select or Type Engagement Based Tactic"
          ? ""
          : vc_engage_based_tactic,
      platform: vc_platform === "Select or Type Platform" ? "" : vc_platform,
      contactType:
        vc_contact_type === "Select or Type Contact Type"
          ? ""
          : vc_contact_type,

      approvalStatus: "",
      isDigital: isDigital ? isDigital : false,
        isPld: isPld ? isPld : false,
        isRm: isRm ? isRm : false,
        isUpdated:isAddMetric? "New Metric":  "New Tactic",
        isPersonalPromotional: false,
        updatedFields: [],
        updated_by: "",

        created_by: getCurrentUserId(),
        
      },
      metricFields: finalAttributes,
    };
    
    if (status === "draft") {
      
      for(const key in tacticObj){
        tacticObj[key].approvalStatus="DRAFT"
        tacticObj[key].active_flag = true;
      }

      tacticAddObj.coreFields.approvalStatus = "DRAFT";
    } else {
      for(const key in tacticObj){
        tacticObj[key].approvalStatus="PENDING"
        tacticObj[key].active_flag = true;
      }
      tacticAddObj.coreFields.approvalStatus = "PENDING";
    }
    const payloadData = tacticObj;
    const addpayloadData = { vendor_entries: [tacticAddObj] };
    if (isEdit) {
      const payloadDataEdit = payloadData;


      const URLParams = new URLSearchParams(window.location.search);
      const id = URLParams.get("delivery_source");


      dispatch(
        updateRequestForApproval({
          payload: payloadDataEdit,
          pageName: "updatePage",
        })
      ).then(res=>{res.payload.statusCode===200? retunToOverview() : 
        addToast({
          toastMessage:"Failed",
          actionText: "",
          variant: "warning",
          position: "top",
          align: "center",
          light: false,
          timeout: 5000,
          dismissible: true,
          inline: false,
          autoDismiss: true,
        });
    });
    } else {
      dispatch(addTactic(addpayloadData)).then(res=>{res.payload.statusCode===200? retunToOverview() : 
        addToast({
          toastMessage:"Failed",
          actionText: "",
          variant: "warning",
          position: "top",
          align: "center",
          light: false,
          timeout: 5000,
          dismissible: true,
          inline: false,
          autoDismiss: true,
        });
    });
    }
    setIsModalOpen(false);
    setisDraftModalOpen(false);
    // setTimeout(()=>retunToOverview(),2500);
  }
  const disabledDimension = ()=>{
    addToast({
      toastMessage: "You are not allowed to edit a core field while adding a new metric. Please submit or cancel your changes first, then edit the record from the Overview screen",
      actionText: "",
      variant: "error",
      position: "top",
      align: "center",
      light: false,
      timeout: 5000,
      dismissible: true,
      inline: false,
      autoDismiss: true,
    });
    return;
  }

  const [getstatusdeliverysource, setStatusDeliverySource] = useState();
  const [getstatussuppliername, setStatusSupplierName] = useState();
  const [getstatusplatform, setStatusPlatform] = useState();
  const [getstatuscontacttype, setStatusContacttype] = useState();
  const [getstatusdeliverysourcetype, setStatusDeliverySourceType] = useState();
  const [getstatusdeliverychannel, setStatusDeliveryChannel] = useState();
  const [getstatusengagementbasedtactic, setstatusEngagementBasedTactic] = useState();
  const [getstatusactivitytype, setStatusActivityType] = useState();
  const [getstatusactivitydatatype, setStatusActivityDataType] = useState();
  const [
    getstatusactivitytypeclassification,
    setStatusActivityTypeClassification,
  ] = useState();
  const [getstatusactivitytypedefinition, setStatusActivityTypeDefinition] =
    useState();
    const [getstatusengdepth, setStatusengdepth] = useState();
    const [getstatusconclass, setStatusconclass] = useState();
    const [getstatusperclass, setStatusperclass] = useState();
    const [getstatusengrtagainst, setStatusengrtagainst] = useState();

  const handleDigitalToggle = (e) => {
    setisDigital(e.target.checked);
  };
  const handlePldToggle = (e) => {
    setisPld(e.target.checked);
  };
  const handleRmToggle = (e) => {
    setisRm(e.target.checked);
  };
  const HandleSetDeliverySource = (e) => {
    setdeliverysourceinput(e);
    setStatusDeliverySource(true);
  };
  const HandleSetSupplierName = (e) => {
    setsuppliernameinput(e);
    setStatusSupplierName(true);
  };
  const HandleSetPlatform = (e) => {
    setplatforminput(e);
    setStatusPlatform(true);
  };
  const HandleSetDeliverySourceType = (e) => {
    setdeliverysourcetypeinput(e);
    setStatusDeliverySourceType(true);
  };
  const HandleSetDeliveryChannel = (e) => {
    setdeliverychannelinput(e);
    setStatusDeliveryChannel(true);
  };
  const HandleSetEngagementBasedTactic = (e) => {
    setengagementbasedtacticinput(e);
    setstatusEngagementBasedTactic(true);
  };
  const HandleSetActivityType = (e, div) => {
    const updatedAtt = stAttributes.map((obj) =>
      obj.id === div ? { ...obj, engagementClassification: e } : obj
    );
    setStAttributes(updatedAtt);
    setactivitytypeinput(e);
    setStatusActivityType(true);
  };
  const HandleSetEngDepth = (e, div) => {
    const updatedAtt = stAttributes.map((obj) =>
      obj.id === div ? { ...obj, engagementDepth: e } : obj
    );
    setStAttributes(updatedAtt);
    setengagementdepthinput(e);
    setStatusengdepth(true);
  };
  const HandleSetPerClass= (e, div) => {
    const updatedAtt = stAttributes.map((obj) =>
      obj.id === div ? { ...obj, performanceBasisClassification: e } : obj
    );
    setStAttributes(updatedAtt);
    setperbasisclassinput(e);
    setStatusperclass(true);
  };
  const HandleSetEngagementRateAgainst = (e, div) => {
    const updatedAtt = stAttributes.map((obj) =>
      obj.id === div ? { ...obj, engagementRateCalculatedAgainst: e } : obj
    );
    setStAttributes(updatedAtt);
    setengrtagainstinput(e);
    setStatusengrtagainst(true);
  };
  const HandleSetConClass = (e, div) => {
    const updatedAtt = stAttributes.map((obj) =>
      obj.id === div ? { ...obj, contractBasisClassification: e } : obj
    );
    setStAttributes(updatedAtt);
    setconbasisclassinput(e);
    setStatusconclass(true);
  };
  const HandleSetActivityDataType = (e, div) => {
    const updatedAtt = stAttributes.map((obj) =>
      obj.id === div ? { ...obj, metricLabel: e } : obj
    );
    setStAttributes(updatedAtt);
    setactivitydatatypeinput(e);
    setStatusActivityDataType(true);
  };
  const HandleSetActivityTypeClassification = (e, div) => {
    const updatedAtt = stAttributes.map((obj) =>
      obj.id === div ? { ...obj, hierarchyClassification: e } : obj
    );
    setStAttributes(updatedAtt);
    setactivitytypeclassificationinput(e);
    setStatusActivityTypeClassification(true);
  };
  const HandleSetContactType = (e) => {
    setcontacttypeinput(e);
    setStatusContacttype(true);
  };
  const HandleSetActivityTypeDefinition = (e, div) => {
    const updatedAtt = stAttributes.map((obj) =>
      obj.id === div ? { ...obj, metricDefinition: e } : obj
    );
    setStAttributes(updatedAtt);
    setactivitytypedefinitioninput(e);
    setStatusActivityTypeDefinition(true);
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
  };
  return (
    <div className="">

      <HeaderNav msg="Omnichannel Metadata Management System (OMMS)" />
      
      <div className="ms-3 p-3">
          <LdsLink href="/omms">
              <LdsIcon
                  description="CaretLeft"
                  label="search"
                  name="CaretLeft"
                  inline
              />
              Back to Overview
          </LdsLink>
      </div>
      <div className="container"><h2>{isEdit ? "Update Tactic Entry" : isAddMetric? "Add New Metirc":"Add A New Tactic Entry"}</h2></div>
      {commonLoader &&
        <div className='spinner'>
          <LdsLoadingSpinner size={100} animationSpeed={500} />
        </div>
      }
      {requestSliceloader &&
        <div className='spinner'>
          <LdsLoadingSpinner size={100} animationSpeed={500} />
        </div>
      }
      {

        !commonLoader && !requestSliceloader && <form>

 
        <div>
          <div>
            {data?.approvalStatus === "REJECTED" && (
              <div className="remarks-div">
                <div className="reject-comment">
                  <div className="inline-reject">
                    <p className="reject-heading">Reject Remarks:</p>
                    &nbsp;&nbsp;
                    <p className="">
                      {data.remarks ? data.remarks : "No remarks found!"}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
         
          <div className="main-entries" onClick={() => { if (isAddMetric) disabledDimension(); }}>

            <div>
              <div className="overview-tile">
                <LdsToast />
                <div className={`inline ${isAddMetric && 'add-met-cursor ' }`}>

                  <label>Delivery Source <span className="asterik-required">*</span></label>
                  <CreatableSelect
                    id="delivery_source"
                    className='select-width'
                    options={deliverySourceOption}
                    isClearable
                    blurInputOnSelect
                    styles={customStyles}
                    value={
                      deliverysourceinput ||
                      (getstatusdeliverysource === true
                        ? {
                            label: "Select or Type Delivery Source",
                            value: null,
                          }
                        : delivery_source
                        ? { label: delivery_source, value: delivery_source }
                        : {
                            label: "Select or Type Delivery Source",
                            value: null,
                          })
                    }
                    onChange={(e) => {
                      HandleSetDeliverySource(e);
                      setd_source(e?.value)
                 
                    }}
                  />
                </div>
          
                <br></br>
                <div className={`inline ${isAddMetric && 'add-met-cursor' }`}>
                  <label>Supplier Name <span className="asterik-required">*</span></label>
                  <CreatableSelect
                    id="supplier_name"
                    className="select-width"
                    options={OverviewOpts?.supplierName || supplierNameOption}
                    isClearable
                    blurInputOnSelect
                    styles={customStyles}
                    isDisabled={loader}
                    isLoading={loader}
                    loadingMessage={() => "loading options.. Please Wait"}
                    value={
                      suppliernameinput ||
                      (getstatussuppliername === true
                        ? { label: "Select or Type Supplier Name", value: null }
                        : supplier_name
                        ? { label: supplier_name, value: supplier_name }
                        : {
                            label: "Select or Type Supplier Name",
                            value: null,
                          })
                    }
                    onChange={(e) => {
                      HandleSetSupplierName(e);
                      sets_name(e?.value)
                 
                    }}
                  />
                </div>
                <br></br>
                <div className={`inline ${isAddMetric && 'add-met-cursor' }`}>
                  <label>Platform <span className="asterik-required">*</span></label>
                  <CreatableSelect
                    className="select-width"
                    id="platform"
                    options={OverviewOpts?.platform || platformOption}
                    isLoading={loader}
                    isDisabled={loader}
                    isClearable
                    blurInputOnSelect
                    styles={customStyles}
                    loadingMessage={() => "loading options.. Please Wait"}
                    value={
                      platforminput ||
                      (getstatusplatform === true
                        ? { label: "Select or Type Platform", value: null }
                        : platform
                        ? { label: platform, value: platform }
                        : { label: "Select or Type Platform", value: null })
                    }
                    defaultValue={platforminput}
                    onChange={(e) => {
                      HandleSetPlatform(e);
                      setpl_form(e?.value)
                    }}
                  />
                </div>
              </div>
              <div className="overview-tile">
                <div className={`inline ${isAddMetric && 'add-met-cursor' }`}>
                  <label>Contact Type <span className="asterik-required">*</span></label>
                  <CreatableSelect
                    id="contact_type"
                    className="select-width"
                    options={OverviewOpts.contactType || contactTypeOption}
                    isLoading={loader}
                    isClearable
                    styles={customStyles}
                    isDisabled={loader}
                    blurInputOnSelect
                    loadingMessage={() => "loading options.. Please Wait"}
                    value={
                      contacttypeinput ||
                      (getstatuscontacttype === true
                        ? { label: "Select or Type Contact Type", value: null }
                        : contact_type
                        ? { label: contact_type, value: contact_type }
                        : { label: "Select or Type Contact Type", value: null })
                    }
                    onChange={(e) => {HandleSetContactType(e); setc_type(e?.value)}}
                  />
                </div>
                <br></br>
                <div className={`inline ${isAddMetric && 'add-met-cursor' }`}>
                  <label>Delivery Source Type <span className="asterik-required">*</span></label>
                  <CreatableSelect
                    id="delivery_source_type"
                    className="select-width"
                    options={
                      OverviewOpts.deliverySourceType ||
                      deliverySourceTypeOption
                    }
                    isLoading={loader}
                    isClearable
                    styles={customStyles}
                    isDisabled={loader}
                    loadingMessage={() => "loading options.. Please Wait"}
                    value={
                      deliverysourcetypeinput ||
                      (getstatusdeliverysourcetype === true
                        ? {
                            label: "Select or Type Delivery Source Type",
                            value: null,
                          }
                        : delivery_source_type
                        ? {
                            label: delivery_source_type,
                            value: delivery_source_type,
                          }
                        : {
                            label: "Select or Type Delivery Source Type",
                            value: null,
                          })
                    }
                    onChange={(e) => {HandleSetDeliverySourceType(e);setd_s_type(e?.value)}}
                  />
                </div>
                <br></br>
                <div className={`inline ${isAddMetric && 'add-met-cursor' }`}>
                  <label>Delivery Channel <span className="asterik-required">*</span></label>
                  <CreatableSelect
                    id="delivery_channel"
                    className="select-width"
                    options={
                      OverviewOpts.deliveryChannel || deliveryChannelOption
                    }
                    blurInputOnSelect
                    isLoading={loader}
                    isClearable
                    styles={customStyles}
                    isDisabled={loader}
                    loadingMessage={() => "loading options.. Please Wait"}
                    value={
                      deliverychannelinput ||
                      (getstatusdeliverychannel === true
                        ? {
                            label: "Select or Type Delivery Channel",
                            value: null,
                          }
                        : delivery_channel
                        ? { label: delivery_channel, value: delivery_channel }
                        : {
                            label: "Select or Type Delivery Channel",
                            value: null,
                          })
                    }
                    onChange={(e) => {HandleSetDeliveryChannel(e);setd_channel(e?.value)}}
                  />
                </div>
                <br></br>
                <div></div>
              </div>
              <div className="overview-tile">
          <div className={`inline ${isAddMetric && 'add-met-cursor' }`}>
                  <label>Engagement Based Tactic <span className="asterik-required">*</span></label>
                  <CreatableSelect
                    id="engagement_based_tactic"
                    className="select-width"
                    options={
                      OverviewOpts.engagementBasedTactic || engagementBasedTacticOption
                    }
                    blurInputOnSelect
                    isLoading={loader}
                    isClearable
                    styles={customStyles}
                    isDisabled={loader}
                    loadingMessage={() => "loading options.. Please Wait"}
                    value={
                      engagementbasedtacticinput ||
                      (getstatusengagementbasedtactic === true
                        ? {
                            label: "Select or Type Engagement Based Tactic",
                            value: null,
                          }
                        : engagement_based_tactic
                        ? { label: engagement_based_tactic, value: engagement_based_tactic }
                        : {
                            label: "Select or Type Engagement Based Tactic",
                            value: null,
                          })
                    }
                    onChange={(e) => {HandleSetEngagementBasedTactic(e);setengage_tactic(e?.value)}}
                  />
                  </div>
                  <br></br>
                  <div>
              <label>Delivery Source Code<span className="asterik-required">*</span></label>
              <LdsTextField
                id='orgName'
                name='orgName'
                value={delSC}
                disabled = {delSC}
                onChange={(e)=>setdelSCinput(e.target.value)}
              />
                </div>
            </div>
            </div>
          
            <div className={`switches ${isAddMetric && 'add-met-cursor' }`}>
            
              {isDigital !== undefined  && isAddMetric&&
                <LdsSwitch
                  label="Digital"
                  value={isDigital}
                  onByDefault={isDigital===true?true:false}
                  onChange={(e) => {
                    handleDigitalToggle(e);
                  }}
                />
              }
               { !isAddMetric && (
                  <LdsSwitch
                    label="Digital"
                    value={isDigital}
                    onByDefault={isDigital===true?true:false}
                    onChange={(e) => {
                      handleDigitalToggle(e);
                    }}
                  />
                )
              }
              {isPld !== undefined &&isAddMetric&&
                <LdsSwitch
                  label="PLD at Tactic Level"
                  value={isPld}
                  onByDefault={isPld===true?true:false}
                  onChange={(e) => {
                    handlePldToggle(e);
                    checkCap();
                  }}
                />
                }
               { !isAddMetric && (
                  <LdsSwitch
                    label="PLD at Tactic Level"
                    value={isPld}
                    onByDefault={isPld===true?true:false}
                    onChange={(e) => {
                      handlePldToggle(e);
                      checkCap();
                    }}
                  />
                )
              }
              {isRm !== undefined &&isAddMetric&&
                <LdsSwitch
                  label="Accepts Tactic IDs in RMs"
                  value={isRm}
                  onByDefault={isRm===true?true:false}
                  onChange={(e) => {
                    handleRmToggle(e);
                    checkCap();
                  }}
                />
                }
                {!isAddMetric && (
                  <LdsSwitch
                    label="Accepts Tactic IDs in RMs"
                    value={isRm}
                    onByDefault={isRm===true?true:false}
                    onChange={(e) => {
                      handleRmToggle(e);
                      checkCap();
                    }}
                  />
                )
              }
            </div>
            {isAddMetric&&<div className='disabled-field'>
            </div>}    
          </div>
       
          {isEdit && !commonLoader && (
            <>
              {commonData &&
                nAttributes.map((data, index) => (
                  <div className= "subdiv">
                    <div className="heading1">Metric {index + 1}:</div>
                    <div className="ov-tile">
                    <div className="overview-tile-1">
                      <div className="inline-box">
                        <label>Metric Label <span className="asterik-required">*</span></label>
                        <CreatableSelect
                          id="act_data_typ"
                          className="select-width-box"
                          options={actDataTypOption}
                          isClearable
                         
                          styles={customStyles}
                          value={
                            {
                              label: data.metricLabel,
                              value: data.metricLabel,
                            } ||
                            (getstatusactivitydatatype === true
                              ? {
                                  label: "Select or Type Metric Label",
                                  value: null,
                                }
                              : data.metricLabel
                              ? {
                                  label: data.metricLabel,
                                  value: data.metricLabel,
                                }
                              : {
                                  label: "Select or Type Metric Label",
                                  value: null,
                                })
                          }
                          defaultValue={activitydatatypeinput}
                          onChange={(e) => {
                            
                            HandleSetActivityDataType(e, data.id);
                            handleEditInputChange(
                              data.id,
                              "metricLabel",
                              e?.value
                            );
                          }}
                        />
                      </div>
                      <div className="inline-box">
                        <label>Engagement Classification <span className="asterik-required">*</span></label>
                        <CreatableSelect
                          id="act_typ"
                          className="select-width-box"
                          options={actTypOption}
                          isClearable
                          styles={customStyles}
                          value={
                            {
                              label: data.engagementClassification,
                              value: data.engagementClassification,
                            } ||
                            (getstatusactivitytype === true
                              ? {
                                  label:
                                    "Select or Type Engagement Classification",
                                  value: null,
                                }
                              : data.engagementClassification
                              ? {
                                  label: data.engagementClassification,
                                  value: data.engagementClassification,
                                }
                              : {
                                  label:
                                    "Select or Type Engagement Classification",
                                  value: null,
                                })
                          }
                          defaultValue={activitytypeinput}
                          onChange={(e) => {
                            HandleSetActivityType(e, data.id);
                            handleEditInputChange(
                              data.id,
                              "engagementClassification",
                              e?.value
                            );
                         
                          }}
                        />
                      </div>
                    
                    <div className="inline-box">
                      <label>Hierarchy Classification <span className="asterik-required">*</span></label>
                      <CreatableSelect
                        id="act_data_typ_class"
                        className="select-width-box"
                        options={actTypClassificationOption}
                        isClearable
                        styles={customStyles}
                        value={
                          {
                            label: data.hierarchyClassification,
                            value: data.hierarchyClassification,
                          } ||
                          (getstatusactivitytypeclassification === true
                            ? {
                                label:
                                  "Select or Type Hierarchy Classification",
                                value: null,
                              }
                            : data.hierarchyClassification
                            ? {
                                label: data.hierarchyClassification,
                                value: data.hierarchyClassification,
                              }
                            : {
                                label:
                                  "Select or Type Hierarchy Classification",
                                value: null,
                              })
                        }
                        defaultValue={activitytypeclassificationinput}
                        onChange={(e) => {
                          HandleSetActivityTypeClassification(e, data.id);
                          handleEditInputChange(
                            data.id,
                            "hierarchyClassification",
                            e?.value
                          );
                        }}
                      />
                    </div>
                    <div className="inline-box">
                      <label> Metric Definition <span className="asterik-required">*</span></label>
                      <CreatableSelect
                        id="act_data_typ_def"
                        className="select-width-box"
                        options={actTypDefinitionOption}
                        isClearable
                        styles={customStyles}
                        value={
                          {
                            label: data.metricDefinition,
                            value: data.metricDefinition,
                          } ||
                          (getstatusactivitytypedefinition === true
                            ? {
                                label: "Select or Type Metric Definition",
                                value: null,
                              }
                            : data.metricDefinition
                            ? {
                                label: data.metricDefinition,
                                value: data.metricDefinition,
                              }
                            : {
                                label: "Select or Type Metric Definition",
                                value: null,
                              })
                        }
                        defaultValue={activitytypedefinitioninput}
                        onChange={(e) => {
                          HandleSetActivityTypeDefinition(e, data.id);
                          handleEditInputChange(
                            data.id,
                            "metricDefinition",
                            e?.value
                          ); 
                        }}
                      />
                    </div>
                    </div>


                    <div className="overview-tile-1">
                      <div className="inline-box">
                        <label>Engagement Depth <span className="asterik-required">*</span></label>
                        <CreatableSelect
                          id="eng_dep"
                          className="select-width-box"
                          options={engDepOptions}
                          isClearable
                          styles={customStyles}
                          value={
                            {
                              label: data.engagementDepth,
                              value: data.engagementDepth,
                            } ||
                            (getstatusengdepth === true
                              ? {
                                  label: "Select or Type Engagement Depth",
                                  value: null,
                                }
                              : data.engagementDepth
                              ? {
                                  label: data.engagementDepth,
                                  value: data.engagementDepth,
                                }
                              : {
                                  label: "Select or Type Engagement Depth",
                                  value: null,
                                })
                          }
                          defaultValue={engagementdepthinput}
                          onChange={(e) => {
                            HandleSetEngDepth(e, data.id);
                            handleEditInputChange(
                              data.id,
                              "engagementDepth",
                              e?.value
                            );
                          }}
                        />
                      </div>
                      <div className="inline-box">
                        <label>Contract Basis Classification <span className="asterik-required">*</span></label>
                        <CreatableSelect
                          id="cont_basis"
                          className="select-width-box"
                          options={conBasClassOptions}
                          isClearable
                          styles={customStyles}
                          value={
                            {
                              label: data.contractBasisClassification,
                              value: data.contractBasisClassification,
                            } ||
                            (getstatusconclass === true
                              ? {
                                  label:
                                    "Select or Type Contract Basis Classification",
                                  value: null,
                                }
                              : data.contractBasisClassification
                              ? {
                                  label: data.contractBasisClassification,
                                  value: data.contractBasisClassification,
                                }
                              : {
                                  label:
                                    "Select or Type Contract Basis Classification",
                                  value: null,
                                })
                          }
                          defaultValue={conbasisclassinput}
                          onChange={(e) => {
                            HandleSetConClass(e, data.id);
                            handleEditInputChange(
                              data.id,
                              "contractBasisClassification",
                              e?.value
                            );
                         
                          }}
                        />
                      </div>
                    
                    <div className="inline-box">
                      <label>Performance Basis Classification <span className="asterik-required">*</span></label>
                      <CreatableSelect
                        id="per_basis"
                        className="select-width-box"
                        options={perBasClassOptions}
                        isClearable
                        styles={customStyles}
                        value={
                          {
                            label: data.performanceBasisClassification,
                            value: data.performanceBasisClassification,
                          } ||
                          (getstatusperclass === true
                            ? {
                                label:
                                  "Select or Type Performance Basis Classification",
                                value: null,
                              }
                            : data.performanceBasisClassification
                            ? {
                                label: data.performanceBasisClassification,
                                value: data.performanceBasisClassification,
                              }
                            : {
                                label:
                                  "Select or Type Performance Basis Classification",
                                value: null,
                              })
                        }
                        defaultValue={perbasisclassinput}
                        onChange={(e) => {
                          HandleSetPerClass(e, data.id);
                          handleEditInputChange(
                            data.id,
                            "performanceBasisClassification",
                            e?.value
                          );
                        }}
                      />
                    </div>
                    <div className="inline-box">
                      <label>Engagement Rate Calculated Against <span className="asterik-required">*</span></label>
                      <CreatableSelect
                        id="eng_rate"
                        className="select-width-box"
                        options={engRtOptions}
                        isClearable
                        styles={customStyles}
                        value={
                          {
                            label: data.engagementRateCalculatedAgainst,
                            value: data.engagementRateCalculatedAgainst,
                          } ||
                          (getstatusengrtagainst === true
                            ? {
                                label: "Select or Type Engagement Rate Calculated Against",
                                value: null,
                              }
                            : data.engagementRateCalculatedAgainst
                            ? {
                                label: data.engagementRateCalculatedAgainst,
                                value: data.engagementRateCalculatedAgainst,
                              }
                            : {
                                label: "Select or Type Engagement Rate Calculated Against",
                                value: null,
                              })
                        }
                        defaultValue={engagementdepthinput}
                        onChange={(e) => {
                          HandleSetEngagementRateAgainst(e, data.id);
                          handleEditInputChange(
                            data.id,
                            "engagementRateCalculatedAgainst",
                            e?.value
                          ); 
                        }}
                      />
                    </div>
                    </div>
                    </div>
                  </div>
                ))}

              
              </>
            
          )}



          {!isEdit &&
            newAttributes.map((div, index) => (
              <div className="subdiv">
                <div className="heading1">Metric {index + 1}:</div>
                <div className="ov-tile">
                <div className="overview-tile-1">
                  <div className="inline-box">
                    <label>Metric Label <span className="asterik-required">*</span></label>
                    <CreatableSelect
                      id="act_data_typ_add"
                      className="select-width-box"
                      options={actDataTypOption}
                      isClearable
                      styles={customStyles}
                      value={
                        stAttributes[index]?.metricLabel ||
                        (getstatusactivitydatatype === true
                          ? {
                              label: "Select or Type Metric Label",
                              value: null,
                            }
                          : data.metricLabel
                          ? { label: data.metricLabel, value: data.metricLabel }
                          : {
                              label: "Select or Type Metric Label",
                              value: null,
                            })
                      }
                      defaultValue={activitydatatypeinput}
                      onChange={(e) => {
                        HandleSetActivityDataType(e, div.id);
                        handleInputChange(div.id, "metricLabel", e?.value);
                      }}
                    />
                  </div>
                  <div className="inline-box">
                    <label>Engagement Classification <span className="asterik-required">*</span></label>
                    <CreatableSelect
                      id="act_typ_add"
                      className="select-width-box"
                      options={actTypOption}
                      isClearable
                      styles={customStyles}
                      value={
                        stAttributes[index]?.engagementClassification ||
                        (getstatusactivitytype === true
                          ? {
                              label: "Select or Type Engagement Classification",
                              value: null,
                            }
                          : data.engagementClassification
                          ? {
                              label: data.engagementClassification,
                              value: data.engagementClassification,
                            }
                          : {
                              label: "Select or Type Engagement Classification",
                              value: null,
                            })
                      }
                      defaultValue={activitytypeinput}
                      onChange={(e) => {
                        HandleSetActivityType(e, div.id);
                        handleInputChange(
                          div.id,
                          "engagementClassification",
                          e?.value
                        );
                      }}
                    />
                  </div>
                  <div className="inline-box">
                    <label>Hierarchy Classification <span className="asterik-required">*</span></label>
                    <CreatableSelect
                      id="act_data_typ_class_add"
                      className="select-width-box"
                      options={actTypClassificationOption}
                      isClearable
                      styles={customStyles}
                      value={
                        stAttributes[index]?.hierarchyClassification ||
                        (getstatusactivitytypeclassification === true
                          ? {
                              label: "Select or Type Hierarchy Classification",
                              value: null,
                            }
                          : data.hierarchyClassification
                          ? {
                              label: data.hierarchyClassification,
                              value: data.hierarchyClassification,
                            }
                          : {
                              label: "Select or Type Hierarchy Classification",
                              value: null,
                            })
                      }
                      defaultValue={activitytypeclassificationinput}
                      onChange={(e) => {
                        HandleSetActivityTypeClassification(e, div.id);
                        handleInputChange(
                          div.id,
                          "hierarchyClassification",
                          e?.value
                        );
                      }}
                    />
                  </div>
                  <div className="inline-box">
                    <label> Metric Definition <span className="asterik-required">*</span></label>
                    <CreatableSelect
                      id="act_data_typ_def_add"
                      className="select-width-box"
                      options={actTypDefinitionOption}
                      isClearable
                      styles={customStyles}
                      value={
                        stAttributes[index]?.metricDefinition ||
                        (getstatusactivitytypedefinition === true
                          ? {
                              label: "Select or Type Metric Definition",
                              value: null,
                            }
                          : data.metricDefinition
                          ? {
                              label: data.metricDefinition,
                              value: data.metricDefinition,
                            }
                          : {
                              label: "Select or Type Metric Definition",
                              value: null,
                            })
                      }
                      defaultValue={activitytypedefinitioninput}

                      onChange={(e) => {
                        HandleSetActivityTypeDefinition(e, div.id);
                        handleInputChange(div.id, "metricDefinition", e?.value);
                      }}
                    />
                  </div>
                </div>
                <div className="overview-tile-1">
                      <div className="inline-box">
                        <label>Engagement Depth <span className="asterik-required">*</span></label>
                        <CreatableSelect
                          id="act_data_typ"
                          className="select-width-box"
                          options={engDepOptions}
                          isClearable
                          styles={customStyles}
                          value={
                            stAttributes[index]?.engagementDepth ||
                        (getstatusengdepth === true
                          ? {
                              label: "Select or Type Engagement Depth",
                              value: null,
                            }
                          : data?.engagementDepth
                          ? {
                              label: data?.engagementDepth,
                              value: data?.engagementDepth,
                            }
                          : {
                              label: "Select or Type Engagement Depth",
                              value: null,
                            })
                      }
                          defaultValue={engagementdepthinput}
                          onChange={(e) => {
                            HandleSetEngDepth(e, div.id);
                            handleInputChange(div.id, "engagementDepth", e?.value);
                          }}
                        />
                      </div>
                      <div className="inline-box">
                        <label>Contract Basis Classification <span className="asterik-required">*</span></label>
                        <CreatableSelect
                          id="act_typ"
                          className="select-width-box"
                          options={conBasClassOptions}
                          isClearable
                          styles={customStyles}
                          value={
                            stAttributes[index]?.contractBasisClassification ||
                            (getstatusconclass === true
                              ? {
                                  label: "Select or Type Contract Basis Classification",
                                  value: null,
                                }
                              : data.contractBasisClassification
                              ? {
                                  label: data.contractBasisClassification,
                                  value: data.contractBasisClassification,
                                }
                              : {
                                  label: "Select or Type Contract Basis Classification",
                                  value: null,
                                })
                          }
                          defaultValue={conbasisclassinput}
                          onChange={(e) => {
                            HandleSetConClass(e, div.id);
                            handleInputChange(div.id, "contractBasisClassification", e?.value);
                          }}
                        />
                      </div>
                    <div className="inline-box">
                      <label>Performance Basis Classification <span className="asterik-required">*</span></label>
                      <CreatableSelect
                        id="act_data_typ_class"
                        className="select-width-box"
                        options={perBasClassOptions}
                        isClearable
                        styles={customStyles}
                        value={
                          stAttributes[index]?.performanceBasisClassification ||
                          (getstatusperclass === true
                            ? {
                                label: "Select or Type Performance Basis Classification",
                                value: null,
                              }
                            : data.performanceBasisClassification
                            ? {
                                label: data.performanceBasisClassification,
                                value: data.performanceBasisClassification,
                              }
                            : {
                                label: "Select or Type Performance Basis Classification",
                                value: null,
                              })
                        }
                        defaultValue={perbasisclassinput}
                        onChange={(e) => {
                          HandleSetPerClass(e, div.id);
                          handleInputChange(div.id, "performanceBasisClassification", e?.value);
                        }}
                      />
                    </div>
                    <div className="inline-box">
                      <label>Engagement Rate Calculated Against <span className="asterik-required">*</span></label>
                      <CreatableSelect
                        id="act_data_typ_def"
                        className="select-width-box"
                        options={engRtOptions}
                        isClearable
                        styles={customStyles}
                        value={
                          stAttributes[index]?.engagementRateCalculatedAgainst ||
                          (getstatusengrtagainst === true
                            ? {
                                label: "Select or Type Engagement Rate Calculated Against",
                                value: null,
                              }
                            : data.engagementRateCalculatedAgainst
                            ? {
                                label: data.engagementRateCalculatedAgainst,
                                value: data.engagementRateCalculatedAgainst,
                              }
                            : {
                                label: "Select or Type Engagement Rate Calculated Against",
                                value: null,
                              })
                        }
                        defaultValue={engrtagainstinput}
                        onChange={(e) => {
                          HandleSetEngagementRateAgainst(e, div.id);
                          handleInputChange(div.id, "engagementRateCalculatedAgainst", e?.value);
                        }}
                      />
                    </div>
                </div>
                </div>
                {numberSC > 1 && (
                  <div className="cross">
                    <LdsIcon
                      onClick={() => {
                        handleDeleteAtt(div.id);
                        SetNumberSC(numberSC - 1);
                      }}
                      name="XCircleFill"
                    />
                  </div>
                )}

              </div>
            ))}
          {!isEdit && (
            <LdsButton
              onClick={() => {
                handleAddAtt();
                SetNumberSC(numberSC + 1);
              }}
              classes="system"
              className="add-att-button"
            >
              <LdsIcon name="PlusCircleFill" className="add-icon" />
              Add Metric
            </LdsButton>
          )}


          <div className="Buttons">
            <LdsButton
              onClick={() => {
                handleClickDraftOpen();
                checkCap();
              }}
            >
              Save As Draft
            </LdsButton>
            <LdsButton
              onClick={() => {
                handleClickSubmitOpen();
                checkCap();
              }}
            >
              Submit Request
            </LdsButton>

          </div>
          <LdsModal
            modalId="testModal"
            open={isModalOpen}
            setModalOpen={setIsModalOpen}
            heading="Are you sure you want to request for approval?"
          >
            <div className="space">
              <LdsButton
                onClick={() => {
                  handleClickSubmit("submit");
                }}
              >
                Yes
              </LdsButton>
              <LdsButton onClick={() => handleClickSubmitClose()}>No</LdsButton>
            </div>
          </LdsModal>
          <LdsModal
            modalId="testModal"
            open={isDraftModalOpen}
            setModalOpen={setisDraftModalOpen}
            heading="Are you sure you want to save as draft?"
          >
            <div className="space">
              <LdsButton
                onClick={() => {
                  handleClickSubmit("draft");
                }}
              >
                Yes
              </LdsButton>
              <LdsButton
                onClick={() => {
                  handleClickDraftClose();
                }}
              >
                No
              </LdsButton>
            </div>
          </LdsModal>
        </div>
        {/* } */}
      </form>
      }
     
      {/* {commonLoader &&
        <div classNam
          e='spinner'>
          <LdsLoadingSpinner size={100} animationSpeed={500} />
        </div>
      } */}
    </div>
  );
}

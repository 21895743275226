import { useEffect, useState } from 'react';
import { LdsModal, LdsLoadingSpinner, LdsTable } from '@elilillyco/ux-lds-react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchHistory } from '../../store/Components/OMMS/history';
import "../../assets/components/OMMS/overview.scss"
import getTimeStamp from '../../data/utils/TimeConversion.js'
// import Data from "./data.json";

const HistoryModal = (props) => {
    // const { isModalOpen, setIsModalOpen } = useLdsModal();
    const dispatch = useDispatch();
    const { modalOpen: openModal, setAuditIsModalOpen, tacticId, sysName } = props;
    const [getHistory, setHistory] = useState()
    useEffect(() => {
        if (openModal) {
            dispatch(fetchHistory({ "tacid": tacticId, "sysname": sysName })).then((data)=> setHistory(data?.payload?.data))
        }
    }, [openModal, tacticId, sysName, dispatch]);
    // console.log(getHistory)
    const historyData = useSelector(({ historySlice }) => historySlice.auditData);
    const historyLoader = useSelector(({ historySlice }) => historySlice.historyLoader);
    const columns = ["Column", "Old Value", "New Value", "Updated By", "Update time"];

    return (
        <>
            <LdsModal
                modalId="HistoryModal"
                open={openModal}
                setModalOpen={setAuditIsModalOpen}
                heading={`${sysName === 'tacct' ? 'Record' : 'Tactic'} Change History`}
            >
                {historyLoader &&
                    <div className='spinner container'>
                        <LdsLoadingSpinner size={90} style={{ zIndex: '3', position: 'absolute' }} />
                    </div>
                }
                {!historyLoader &&
                    <LdsTable>
                        <thead>
                            <tr>
                                {
                                    columns.map((header, index) =>
                                        <th scope="col">{header}</th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {historyData.map((value, rowIndex) =>
                                <tr role="row" key={rowIndex}>
                                    <td>{value.column}</td>
                                    <td>{typeof value.old_value === 'boolean' ? (value.old_value ? "true" : "false") : value.old_value}</td>
                                    <td>{typeof value.new_value === 'boolean' ? (value.new_value ? "true" : "false") : value.new_value}</td>
                                    <td>{value.updated_by}</td>
                                    <td>{getTimeStamp(value.updated_date)}</td>
                                </tr>)}
                        </tbody>
                    </LdsTable>
                }
                {getHistory?.length === 0 && !historyLoader ?
                    <p className="audit-no-result container mt-5">No History available</p> : ''
                }
            </LdsModal>
        </>
    );
}

export default HistoryModal;
import { LdsButton } from '@elilillyco/ux-lds-react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';


const ErrorPage = ({ errorCode }) => {
  const allRoles = useSelector(({user}) => user.AllRole);
  const url = window.location.href;
  const lastSegment = url.substring(url.lastIndexOf('/') + 1);

  let errorMessage;
  if (lastSegment === "unauthorized-access" || allRoles.length === 0 ) {
    errorMessage = "Oops! You don't have access to this page.";
  }
  else {
    errorMessage = "Oops! The page you're looking for does not exist.";
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Error {errorCode}</h1>
      <p style={styles.message}>{errorMessage}</p>
      <div style={styles.buttonsContainer}>
        <LdsButton onClick={() => window.location.href = "/"} type="button">
          Go Back to Home Page
        </LdsButton>
        <h4 className='mt-4'>OR</h4>
        <h3>Please Contact Your Administrator.</h3>
      </div>
    </div>
  );
};

export default ErrorPage;

const styles = {
  container: {
    textAlign: 'center',
    margin: 'auto',
    marginTop: '100px',
  },
  message: {
    fontSize: '1.5rem',
    marginBottom: '20px',
  },
};
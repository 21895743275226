import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import capDataDetails from '../../../api/actions/omms/capDataDetails';
import capAuditDetails from '../../../api/actions/omms/capAuditDetails';


export const initialState = {
  data: [],
  totalPages: 0,
  loader: false
}

const fetchCapData = createAsyncThunk(
  "/cap-data-detail",
  async (queryParams, currentPage) => {
    const response = await capDataDetails(queryParams);
    return await response.data;
  }
);
const fetchCapDataforModal = createAsyncThunk(
  "/cap-audit",
  async (queryParams, currentPage) => {
    const response = await capAuditDetails(queryParams);
    return await response.data;
  }
);

const capDataSlice = createSlice({
  name: 'cap',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchCapDataforModal.fulfilled, (state, action) => {
      state.totalPages = action.payload.numberOfPages;
      state.loader = false;
    });
    builder.addCase(fetchCapDataforModal.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(fetchCapDataforModal.rejected, (state, action) => {
      state.user = action.payload;
      state.loader = false;
    });
  },
});

export { fetchCapData, fetchCapDataforModal };
export default capDataSlice.reducer;